<template>
  <div>
      <navBar :title='"Beheer"' ></navBar>
      <router-view></router-view>
      <div id="version">
        Versie {{ version }} &middot; Skipiste {{ new Date().getFullYear() }}
      </div>
  </div>
</template>

<script>
import navBar from '@/components/beheer/Nav.vue'

export default {
  components: {
    navBar
  },
  name: 'Container',
  methods: {
    uitloggen: function () {
      this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'login' })
    }
  },
  computed: {
    version () {
      return this.$store.state.vergaarbak.packageVersion
    }
  }
}
</script>

<style>

#main-admin-content{
  display: grid;
  grid-template-columns: 1fr 6fr;
}
.bg-themed-light{
    min-height: 100vh;
    /* background: #f4eded; */
    /* background: #FEF9F6; */
    background: var(--background-light);
}

ul {
    list-style-type: none;
}

.navbar{
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
}

#dash-nav{
    /* margin-top: 61px; */
    padding-top: 12px;
    /* border-right: 1px solid #e0e0e0; */
    /* background: #FEF9F6; */
    background: var(--background-light);
}

#dash-content{
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
    background: #fff;
    min-height: 100vh;
}

p{
    color: #6a6a6a;
    font-family: "Open Sans",sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
}

.btn{
    text-decoration: none !important;
    border-radius: 10px !important;
    border: none !important;
    /* text-transform: uppercase !important; */

}

.btn-primary{
    background-color: var(--skipiste-color) !important;
    color: #fff !important;
}

.btn-secondary{
  background: rgba(0,0,0,.34) !important;
}

.sk-card{
  height: 300px;
  border-radius: 5px;
  max-width: 400px;
  width: 100%;
  background: #f4eded;
  margin-bottom: 10px;
}

.sk-card2{
  height: 250px;
}

.navbar-toggler{
  display: none;
}

.accent{
  color: var(--vibrant-accent);
}

#sidenav{
  position: sticky;
  top: 12px;
}

@media  (max-width: 768px){
    #main-admin-content{
      display: block
    }
    .navbar-toggler{
      display: block;
    }
    p{
        color: #6a6a6a;
        font-family: "Open Sans",sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.2;
        letter-spacing: 0px;
    }
    .navbar{
        position: relative;
    }
    #dash-content{
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
    }
    #dash-nav{
        padding-top: 0;
        margin-top: 0;
    }
    #sidenav{
      padding-top: 12px;
      padding-bottom: 12px;
      position: relative;
    }
}

#dash-nav .list-group-item{
    border-radius: 10px;
    margin-bottom: 0.5rem;
    transition: all 50ms ease-in-out;
    border: none;
    font-weight: 500;
    letter-spacing: .1px;
    background: none;
}

#dash-nav .list-group-item svg{
  transform: all 50ms ease-in-out;
      margin-right: 10px;
}

#dash-nav .list-group-item:hover{
    color: var(--skipiste-color);
}
#dash-nav .list-group-item.router-link-exact-active{
  color: var(--skipiste-color) !important;
}

#dash-nav .list-group-item.router-link-exact-active svg{
  color:#fff;
  margin-right: 10px;
}

#dash-nav .list-group-item.router-link-exact-active::before{
    content: "";
    position: absolute;
    top: 0;
    left: -61px;
    width: 100px;
    z-index: -1;
    height: 100%;
    background: var(--skipiste-color);
    border-radius: 10px;
    transition: all 50ms ease-in-out;
}

#version{
  font-size: 12px;
  font-weight: 400;
  color: #6a6a6a;
  bottom: 0;
  right: 0;
  margin-top: -20px;
  text-align: center;
}

</style>
