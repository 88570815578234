<template>
    <nav class="navbar navbar-expand-md navbar-light">
      <div class="container-fluid">
        <router-link class="navbar-brand ms-1 d-inline-flex align-items-center" :to="{ name: 'Vergaarbak' }">
          <div>
            <img src="/img/branding/skipiste_logo_clean.svg" alt="" width="40">
          </div>
          <h5 class='mb-0 mt-0 ms-3'>{{title}}</h5>
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsable-nav" aria-controls="sidenav" aria-expanded="false" aria-label="Toon navigatie">
          <font-awesome-icon :icon="['fad', 'bars']" />
        </button>
        <div id='collapsable-nav' class="collapse navbar-collapse">
          <ul id='centernav' class="navbar-nav mx-auto mb-0 mb-lg-0">
            <li class="nav-item "><router-link class="nav-link" :to="{ name: 'Vergaarbak' }">Aanmeldingen</router-link></li>
            <li class="nav-item "><router-link class="nav-link" :to="{ name: 'Historiebak' }">Historie</router-link></li>
          </ul>
          <ul class="navbar-nav mb-2 mb-lg-0 ms-auto">
            <li class="nav-item">
                <a class="nav-link" @click='uitloggen()'>Uitloggen</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
</template>

<script>
export default {
  name: 'navBar',
  props: {
    title: {
      type: String,
      default: 'Skipiste'
    }
  },
  methods: {
    uitloggen: function () {
      this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
<style scoped>
.navbar-light .navbar-nav .nav-link{
  cursor: pointer;
  color: #fff;
  font-weight: 500;
}

.navbar-light .navbar-nav .nav-link:hover{
  color: var(--skipiste-color);
}

.navbar-light .navbar-nav .nav-link.router-link-exact-active{
  color: var(--skipiste-color);
  font-weight: 700;
}

.navbar{
  padding: 12px 0;
  background: var(--sp-darker);
  /* background: #010101; */
  border-bottom: none;
}

/* .navbar-brand > div{
  background: #fff;
  height: 100%;
  margin: -12px;
  margin-left: -17px;
  padding: 12px;
  border-radius: 0 10px 10px 0;
  margin-right: 0px;
} */

.navbar-brand img{
  filter: invert(1);
}

.navbar-brand h5{
  color: #fff;
}

#centernav{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

@media screen and (max-width: 992px){
  #centernav{
    position: initial;
    left: 0;
    top: 0;
    transform: none;
  }
}

.navbar-toggler{
  border: none;
}

.navbar-toggler svg{
  color: #fff;
}
</style>
